import { theme } from '@styles/theme';
import clsx from 'clsx';
import React from 'react';
import styled, { css } from 'styled-components';

export interface HighlightedHeadingProps {
  level?: keyof JSX.IntrinsicElements;
  title: string;
  decoration_color?: string;
  variant?: 'default' | 'small';
  isDisabled?: boolean;
}

const StyledWrapper = styled.span<{
  decorationColor?: HighlightedHeadingProps['decoration_color'];
}>`
  span {
    ${(props) =>
      props.decorationColor &&
      css`
        display: block;
        background: ${(props: any) => props.decorationColor};
        color: white;
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
        padding: 1rem;
        margin: -1rem;
      `}
  }
`;

const HighlightedHeading = ({
  level: Tag = 'h1',
  title,
  decoration_color,
  variant = 'default',
  isDisabled,
}: HighlightedHeadingProps) => {
  return (
    <Tag>
      <StyledWrapper
        className={clsx('font-extrabold', {
          'text-3xl lg:text-7xxl': variant === 'default',
          'text-3xl lg:text-5xxl': variant === 'small',
          'text-neutral-5': isDisabled,
        })}
        dangerouslySetInnerHTML={{ __html: title }}
        decorationColor={
          isDisabled ? theme.colors.neutral[5] : decoration_color
        }
      />
    </Tag>
  );
};

export default HighlightedHeading;
