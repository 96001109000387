import clsx from 'clsx';
import React, { ReactNode } from 'react';
import BreadcrumbItem, { BreadcrumbItemProps } from './BreadcrumbItem';
import BreadcrumbItemSeparator from './BreadcrumbItemSeparator';
import { useAppContext } from 'src/context/app.context';
import { getFormattedSeoYoastBreadcrumbs } from 'src/utils/getFormattedSeoYoastBreadcrumbs';
import Container from '@components/containers/Container';

export interface BreadcrumbsProps {
  items?: Array<
    BreadcrumbItemProps & {
      title: ReactNode | string;
    }
  >;
  isPadding?: boolean;
  inContainer?: boolean;
}

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({
  items,
  isPadding = true,
  inContainer,
}) => {
  const { yoastHeadJson } = useAppContext();

  const breadcrumbs: BreadcrumbsProps['items'] =
    items || getFormattedSeoYoastBreadcrumbs(yoastHeadJson as any);

  const Inner = () => (
    <>
      {breadcrumbs && (
        <div
          className={clsx('', {
            'mt-20 mb-32': isPadding,
          })}
        >
          <div className="flex items-center flex-wrap gap-y-6">
            {breadcrumbs.map((item, index) => {
              const isLastItem = index === breadcrumbs.length - 1;

              return (
                <span key={index} className="flex">
                  <BreadcrumbItem isCurrent={isLastItem} url={item.url}>
                    {typeof item.title === 'string' ? (
                      <span dangerouslySetInnerHTML={{ __html: item.title }} />
                    ) : (
                      item.title
                    )}
                  </BreadcrumbItem>
                  {!isLastItem && <BreadcrumbItemSeparator />}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      {inContainer && (
        <Container>
          <Inner />
        </Container>
      )}
      {!inContainer && <Inner />}
    </>
  );
};

export default Breadcrumbs;
