import Separator from '../../../../assets/images/icons/arrow-double-chevron.svg';

const BreadcrumbItemSeparator: React.FunctionComponent = () => {
  return (
    <span className="mx-4">
      <Separator />
    </span>
  );
};

export default BreadcrumbItemSeparator;
