import HeroBanner, { HeroBannerProps } from '@components/views/HeroBanner';
import React from 'react';

export type BasicHeroProps = Omit<HeroBannerProps, 'decorationColor'>;

const BasicHero: React.FunctionComponent<BasicHeroProps> = (props) => {
  return <HeroBanner {...props} />;
};

export default BasicHero;
