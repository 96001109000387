import HeroBanner, { HeroBannerProps } from '@components/views/HeroBanner';
import React from 'react';
import { getFormattedSeoYoastBreadcrumbs } from 'src/utils/getFormattedSeoYoastBreadcrumbs';
import { usePostTypeCollection } from 'src/services/api/queries/hooks/usePostTypeCollection';
import { useAppContext } from 'src/context/app.context';

export type ProductHeroProps = Omit<
  HeroBannerProps,
  'breadcrumbs' | 'decorationColor'
>;

const ProductHero: React.FunctionComponent<ProductHeroProps> = (props) => {
  const { permalink } = useAppContext();

  const pageFromSlugData = usePostTypeCollection(
    'product',
    {
      permalink,
    },
    {
      enabled: !!permalink,
    },
  ).data?.data;

  if (!pageFromSlugData) return null;

  const [pageData] = pageFromSlugData;

  const breadcrumbs =
    pageData && getFormattedSeoYoastBreadcrumbs(pageData.yoast_head_json);

  const decorationColor = pageData && pageData.meta_data.colors.main;

  return (
    <HeroBanner
      breadcrumbs={breadcrumbs}
      decoration_color={decorationColor}
      {...props}
    />
  );
};

export default ProductHero;
