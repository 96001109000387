import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

export interface BreadcrumbItemProps {
  isCurrent?: boolean;
  url?: string;
}

const BreadcrumbItem: React.FunctionComponent<BreadcrumbItemProps> = ({
  children,
  isCurrent,
  url,
}) => {
  if (url && !isCurrent) {
    return <BreadcrumbItemLink url={url}>{children}</BreadcrumbItemLink>;
  }

  const Tag = isCurrent ? 'h1' : 'span';

  return (
    <Tag
      className={clsx('text-xs', {
        'text-neutral-4': !isCurrent,
        'font-medium text-neutral-7': isCurrent,
      })}
    >
      {children}
    </Tag>
  );
};

const BreadcrumbItemLink: React.FunctionComponent<{ url: string }> = ({
  url,
  children,
}) => {
  return (
    <BreadcrumbItem>
      <Link href={url}>
        <a className="hover:underline hover:font-medium hover:text-neutral-7">
          {children}
        </a>
      </Link>
    </BreadcrumbItem>
  );
};

export default BreadcrumbItem;
