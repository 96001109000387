import React from 'react';
import Container from '@components/containers/Container';
import HighlightedHeading, {
  HighlightedHeadingProps,
} from '@components/views/HighlightedHeading';
import { IButtonACF } from 'src/types/IButtonACF';
import Button from '@components/views/Button';
import Image from 'next/image';
import ContainImage from '@components/views/ContainImage';
import Breadcrumbs from '../Breadcrumbs';
import styled from 'styled-components';

export interface HeroBannerProps {
  buttons?: Array<{
    button: IButtonACF;
  }>;
  heading: HighlightedHeadingProps;
  description?: string;
  image: string;
  breadcrumbs?: any;
  decoration_color?: any;
}

const StyledDescription = styled.div`
  p {
    &:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }
  }

  li {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
`;

const HeroBanner = (props: HeroBannerProps) => {
  const {
    buttons,
    heading,
    description,
    image,
    breadcrumbs,
    decoration_color,
  } = props;

  return (
    <Container
      className="grid grid-cols-12 border-b border-neutral-1"
      outerMargin="RIGHT"
    >
      <div className="col-span-full md:col-span-6 xl:col-span-5 flex flex-col py-20 relative z-10">
        {breadcrumbs && (
          <div className="mb-20">
            <Breadcrumbs items={breadcrumbs} />
          </div>
        )}
        <div className="mb-24 lg:mb-56 max-w-[95%]">
          <HighlightedHeading
            {...heading}
            decoration_color={decoration_color}
          />
        </div>
        {!!description && (
          <StyledDescription
            className="mb-24 lg:mb-56 text-neutral-5 max-w-xl"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}
        {!!buttons && (
          <div className="flex gap-8 lg:gap-32 flex-wrap mt-auto md:mb-70">
            {buttons.map((item) => {
              const { button } = item;

              return (
                <Button key={button.title} href={button.url} {...button}>
                  {button.title}
                </Button>
              );
            })}
          </div>
        )}
      </div>
      {image && (
        <div className="col-span-full relative md:col-span-6 xl:col-span-7 lg:-ml-100 self-center lg:self-end">
          <Image layout="fill" src={image} alt="tło" objectFit="cover" />
          <div className="relative top-0 left-0 w-2/3 xl:w-1/2">
            <ContainImage objectFit="cover" src="/images/Squares.png" alt="" />
          </div>
        </div>
      )}
    </Container>
  );
};

export default HeroBanner;
