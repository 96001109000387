import { HeroBannerProps } from '@components/views/HeroBanner';
import ProductHeroView from '@components/views/Hero/Product';
import BasicHero from '@components/views/Hero/BasicHero';
import { useAppContext } from 'src/context/app.context';

export type ProductHeroProps = Omit<
  HeroBannerProps,
  'breadcrumbs' | 'decorationColor'
>;

const Hero: React.FunctionComponent<ProductHeroProps> = (props) => {
  const { permalink } = useAppContext();

  return permalink && permalink.includes('produkty') ? (
    <ProductHeroView {...props} />
  ) : (
    <BasicHero {...props} />
  );
};

export default Hero;
