export const getFormattedSeoYoastBreadcrumbs = (yoast_head_json: {
  [key: string]: any;
}) =>
  yoast_head_json
    ? yoast_head_json.schema['@graph']
        .find((item: any) => item['@type'] === 'BreadcrumbList')
        .itemListElement.map(({ name, item }: any) => ({
          title: name,
          url: item,
        }))
    : [];
